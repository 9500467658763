var margin_profile = 0;

$(function() { // ready!
    setTimeout(function() {
        dim_cont();
	}, 100);
});

$(window).on("resize",function() {
    $('#nav.navbar').removeClass('h-100');
    dim_cont();
});

function change_height_navbar(){
	if( $('#nav .navbar-toggler').hasClass('collapsed') ){
		$('body').removeClass('noscroll');
	}
	else{
		$('body').addClass('noscroll');
		setTimeout(function() { height_profile(); }, 200);
	}
}

function height_cont(id){
	$("#cont_dx_"+id+" .card.custom1").height('unset');
	$("#title_dx_"+id).height('unset');
	if( $(window).width() >= 992 ){
		if( $("#cont_sx_"+id).height() > $("#cont_dx_"+id).height() ){
			$("#cont_dx_"+id).children(":first").height($("#cont_sx_"+id).height());
		}
		$("#title_dx_"+id).height($("#title_sx_"+id).height());
	}
}

function height_profile(){
	$('#profile').css('margin-top', 'unset' );
	if( $(window).width() < 992 && $(window).height() > 630 ){
		if( margin_profile == 0 ){ margin_profile = $(window).height() - $('#nav-container').height() - 60; }
		$('#profile').css('margin-top', margin_profile );
	}
}

function dim_cont(){
	height_cont(1);
	height_cont(2);
}

function show_profile(){
	if( $(window).width() >= 992 ){
		if( $('#btn_show_profile').hasClass('active') ){
			$('#btn_show_profile').removeClass('active');
			$('#profile').css("visibility","hidden");
		}
		else{
			$('#btn_show_profile').addClass('active');
			$('#profile').css("visibility","visible");
		}
	}
	else{
		$('#profile').css("visibility","visible");
	}
}

